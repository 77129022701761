<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select v-model="department.company_id" :items="companies" item-text="comp_fantasia" item-value="comp_id" label="Selecione uma empresa..." :rules="rules.companyRules" required outline></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="department.dept_nome" :rules="rules.nomeRules" label="Nome" outline required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="department.dept_email" :rules="rules.emailRules" label="Email" outline required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-switch color="lime" v-model="department.dept_show_link_contato" label="Mostrar em Setor no link Contato"></v-switch>
                    <v-switch color="lime" v-model="department.dept_status" label="Status"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        <v-icon dark class="mdi mdi-check"></v-icon>
                        {{btnLabel}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {
        this.$store.dispatch('loadCompanies');
    },
    computed: {
        companies() {
            return this.$store.state.companies.items.data;
        },
    },
    props: {
        department: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    dept_id: '',
                    company_id: 1,
                    dept_nome: '',
                    dept_email: '',
                    dept_show_link_contato: false,
                    dept_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    name: "FormDepartmentComponent",

    data() {
        return {
            valid: true,
            rules: {
                companyRules: [
                    v => !!v || 'Campo Empresa é obrigatório',
                ],
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 200) || 'Nome precisa ter ate 200 Caracteres'
                ],
                emailRules: [
                    v => !!v || 'Campo E-Mail é obrigatório',
                    v => /.+@.+/.test(v) || 'E-mail precisa ser válido'
                ],
            },
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateDepartment' : 'storeDepartment';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.$store.dispatch(action, this.department)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'departments'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}
</style>
