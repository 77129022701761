<template>

<span>
    <v-layout mb-4>
      <v-flex xs10 ml-3>
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Departamento - Edição</h2>
      </v-flex>
      <v-flex xs2 mr-3 text-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'departments'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
    
    <form-department :department="department" :update="true"></form-department>
</span>
        
</template>

<script>
import FormDepartmentComponent from "./partial/FormDepartmentComponent";

export default {
  name: "EditBannerComponent",
  created() {
    this.loadDepartment()
  },
  props: {
    dept_id: {
      require: true
    }
  },
  data () {
    return {
      department: {
        dept_id: '',
        dept_nome: '',
        dept_status: false,
      },
    }
  },
  methods: {
    loadDepartment() {
      this.$store.dispatch('loadDepartment', this.dept_id)
        .then(response => this.department = response)
        .catch(error => {
          this.$swal({
            position: "center",
            icon: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
  },
  components: {
    formDepartment: FormDepartmentComponent
  }
};
</script>

<style scoped>

</style>
